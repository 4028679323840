define("eventapp-stream/helpers/faved", ["exports", "event-components/helpers/faved"], function (_exports, _faved) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _faved.default;
    }
  });
  Object.defineProperty(_exports, "faved", {
    enumerable: true,
    get: function () {
      return _faved.faved;
    }
  });
});