define("eventapp-stream/login/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let Login = (_dec = Ember.inject.service('services/amplify'), _dec2 = Ember.inject.service('router'), (_class = class Login extends Ember.Controller.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _defineProperty2.default)(this, "email", '');
      (0, _defineProperty2.default)(this, "firstname", '');
      (0, _defineProperty2.default)(this, "lastname", '');
      (0, _defineProperty2.default)(this, "username", '');
      (0, _defineProperty2.default)(this, "code", '');
      (0, _defineProperty2.default)(this, "queryParams", ['error']);
      (0, _defineProperty2.default)(this, "error", '');
      (0, _defineProperty2.default)(this, "showCode", true);
    }

    *login() {
      try {
        const variables = {
          where: {
            username: this.username,
            invitecode: this.code,
            client: {
              id: this.get('amplify.currentClient.id')
            }
          }
        };
        let users = yield this.apollo.query({
          query: query,
          variables: variables,
          fetchPolicy: 'cache-first'
        }, 'users');

        if (users.length > 0) {
          console.log('redirect to init');
          this.get('router').transitionTo('init', this.code, this.username);
        } else {
          this.get('router').transitionTo('init', '');
        }
      } catch (e) {
        console.error(e);
      }
    }

    *resendInviteEmail(email) {
      console.log(`resend email to: ${this.email}`);
      const variables = {
        where: {
          email: this.email,
          firstname: this.firstname,
          lastname: this.lastname,
          client: {
            id: this.get('amplify.currentClient.id')
          }
        }
      };
      let users = yield this.apollo.query({
        query: query,
        variables: variables,
        fetchPolicy: 'cache-first'
      }, 'users');

      if (users.length > 0) {
        console.log(users);
      } else {
        this.set('error', 'Wir haben diesen Benutzer nicht gefunden!');
        return;
      }

      let emailtemplate = this.get('amplify.currentTheme.data.emailtempalteCode');
      console.log(this.get('amplify.currentTheme'), typeof emailtemplate);

      if (typeof emailtemplate !== 'string' || emailtemplate.length === 0) {
        emailtemplate = `
      Sehr geehrte(r) {salutation} {firstname} {lastname},<br>
      Sie haben ihre Zugangsdaten angefordert.<br><br>
      Benutzername: {username}<br>
      Code (Passwort): {code}<br>
      `;
      } else {
        emailtemplate = emailtemplate.replace(/\n/g, '<br />');
      }

      yield fetch('https://u6laok016g.execute-api.eu-west-1.amazonaws.com/prod/order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.email,
          subject: `Ihr Code für ${this.get('amplify.currentClient.name')}`,
          content: emailtemplate.replace('{code}', users.firstObject.invitecode).replace('{username}', users.firstObject.username).replace('{salutation}', users.firstObject.salutation).replace('{firstname}', users.firstObject.firstname).replace('{lastname}', users.firstObject.lastname)
        })
      }).then(response => response.json());
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "login", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "resendInviteEmail", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "resendInviteEmail"), _class.prototype)), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your controllers.

  _exports.default = Login;
});