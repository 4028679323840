define("eventapp-stream/anonym/route", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Anonym extends Ember.Route.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
    }

    async model() {
      const client = await this.modelFor('application');
      const uid = (0, _emberUuid.v4)();
      const variables = {
        data: {
          username: uid,
          firstname: 'Anonym',
          lastname: 'Anonym',
          client: {
            connect: {
              id: client.id
            }
          }
        }
      };
      let user = await this.get('apollo').mutate({
        mutation,
        variables
      }, 'createUser');
      window.localStorage.setItem('user', user.id);
      console.log(`Login of Anonym User ${user.id}`);
      await this.transitionTo('index');
      window.location.reload();
    }

  }

  _exports.default = Anonym;
});