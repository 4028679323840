define("eventapp-stream/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Login extends Ember.Route.extend({// anything which *must* be merged to prototype here
  }) {
    beforeModel() {
      this.transitionTo('index');
    }

  }

  _exports.default = Login;
});