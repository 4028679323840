define("eventapp-stream/standalone/route", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Standalone extends Ember.Route.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "queryParams", {
        userId: {
          refreshModel: true
        }
      });
      (0, _defineProperty2.default)(this, "userId", null);
    }

    model(params) {
      console.log(params);

      if (params.userId !== null) {
        window.localStorage.setItem('user', params.userId);
      }

      this.amplify.initSession();
    }

  }

  _exports.default = Standalone;
});