define("eventapp-stream/init/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let Init = (_dec = Ember.inject.service('services/amplify'), (_class = class Init extends Ember.Route.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
    }

    serialize(model, params) {
      // If we got here without an ID (and therefore without a model)
      // Ensure that we leave the route param in the URL blank (not 'undefined')
      if (!model) {
        return {
          user_name: ''
        };
      } // Otherwise, let Ember handle it as usual


      return this._super.apply(this, arguments);
    }

    async model(params) {
      try {
        const client = await this.modelFor('application');
        let variables = {
          where: {
            invitecode: params.user_id,
            client: {
              id: client.id
            }
          }
        };
        console.log(params, client);
        variables.where.username = params.user_name;
        let users = await this.apollo.query({
          query,
          variables,
          fetchPolicy: 'network-only'
        }, 'users');
        console.log('#2', users);

        if (!Ember.isEmpty(users)) {
          window.localStorage.setItem('user', users.firstObject.id);
          console.log('#3');
        } else {
          console.log('#4');

          if (client.loginmode === 'CODE') {
            console.log('#7');
            this.transitionTo('login', {
              queryParams: {
                error: 'Dieser Benutzer ist uns nicht bekannt, oder das Passwort ist falsch'
              }
            });
          }

          if (client.loginmode === 'DEFAULT') {
            console.log('#8');
            this.transitionTo('nouser');
          }

          console.log('#5');
          return;
        }

        let route = Ember.getOwner(this).lookup(`route:application`);
        route.refresh();
        this.transitionTo('application');
        return;
      } catch (e) {
        console.error(e);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Init;
});