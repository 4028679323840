define("eventapp-stream/nouser/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency-decorators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let Nouser = (_dec = Ember.inject.service('services/amplify'), (_class = class Nouser extends Ember.Controller.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
      (0, _defineProperty2.default)(this, "email", '');
      (0, _defineProperty2.default)(this, "firstname", '');
      (0, _defineProperty2.default)(this, "lastname", '');
      (0, _defineProperty2.default)(this, "error", '');
    }

    *resendInviteEmail(email) {
      console.log(`resend email to: ${this.email}`);
      const variables = {
        where: {
          email: this.email,
          firstname: this.firstname,
          lastname: this.lastname,
          client: {
            id: this.get('amplify.currentClient.id')
          }
        }
      };
      let users = yield this.apollo.query({
        query: query,
        variables: variables,
        fetchPolicy: 'cache-first'
      }, 'users');

      if (users.length > 0) {
        console.log(users);
      } else {
        this.set('error', 'Wir haben diesen Benutzer nicht gefunden!');
        return;
      }

      yield fetch('https://u6laok016g.execute-api.eu-west-1.amazonaws.com/prod/order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.email,
          emailSender: this.amplify.currentTheme.data.emailsender,
          subject: 'Ihr persönlicher Link zur Registrierung | Zwischenbilanzkonferenz Strategiedialog Automobilwirtschaft BW ',
          content: `
          Sehr geehrte Damen und Herren,
 

          wie gewünscht erhalten Sie Ihren persönlichen <a href="https://${window.location.hostname}/#/i/${users.firstObject.invitecode}/${users.firstObject.username}">Link</a> zur Registrierung.  

      
          
          Bei allen Fragen zur Anmeldung steht Ihnen Frau Maileen Zehnder, Agentur u-motions GmbH, unter registrierung@sda2020-partner.de oder telefonisch dienstags und donnerstags von 10:00 bis 12:00 Uhr unter 0721 915778 733 gerne zur Verfügung.
          
           
          
          Herzliche Grüße,
          
           
          
          Organisationsteam SDA 2020
          
           
          
          E-Mail:  registrierung@sda2020-partner.de
          
          Tel.:       0721 915 778 733 (Dienstag und Donnerstag, 10:00 - 12:00 Uhr)
        `.replace(/\n/g, '<br />')
        })
      }).then(response => response.json());
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "resendInviteEmail", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "resendInviteEmail"), _class.prototype)), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your controllers.

  _exports.default = Nouser;
});