define("eventapp-stream/components/image-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nibOjdum",
    "block": "{\"symbols\":[\"imagepath\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"image-modal\"]],{\"statements\":[[7,\"div\",false],[12,\"class\",\"image-modal\"],[3,\"action\",[[23,0,[]],[24,[\"click\"]]]],[8],[0,\"\\n\"],[4,\"async-await\",[[23,0,[\"imagepath\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",false],[12,\"id\",[22,\"id\"]],[12,\"alt\",\"modal image\"],[12,\"src\",[23,1,[]]],[3,\"did-insert\",[[28,\"action\",[[23,0,[]],\"didInsertImage\"],null]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"image-close\"],[8],[0,\"\\n      X\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "eventapp-stream/components/image-modal/template.hbs"
    }
  });

  _exports.default = _default;
});