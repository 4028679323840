define("eventapp-stream/helpers/searchable-select-highlight-match", ["exports", "ember-searchable-select/helpers/searchable-select-highlight-match"], function (_exports, _searchableSelectHighlightMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _searchableSelectHighlightMatch.default;
    }
  });
  Object.defineProperty(_exports, "searchableSelectHighlightMatch", {
    enumerable: true,
    get: function () {
      return _searchableSelectHighlightMatch.searchableSelectHighlightMatch;
    }
  });
});