define("eventapp-stream/components/module-layouts/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  let ModuleLayouts = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service('services/amplify'), _dec(_class = (_class2 = class ModuleLayouts extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
      (0, _defineProperty2.default)(this, "isIframe", false);
      (0, _defineProperty2.default)(this, "modules", ['app-chat-profiles', 'app-chat', 'twitter-wall', 'vote-list-module', 'app-avatar-module', 'app-meeting']);
      this.get('amplify').on('unity:vote', this, 'openVote');
      this.get('amplify').on('unity:chat', this, 'openChat');
    }

    openVote() {
      console.log('openVote'); //this.set('layoutId', 2);
    }

    openChat() {
      console.log('openChat'); //this.set('layoutId', 2);
    }

    willDestroyElement() {
      console.log('willDestroyElement');
      this.amplify.off('unity:vote', this, 'openVote');
      this.amplify.off('unity:vote', this, 'openChat');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = ModuleLayouts;
});