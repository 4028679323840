define("eventapp-stream/application/route", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let Application = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service('services/amplify'), _dec3 = Ember.inject.service('user'), _dec4 = Ember._action, (_class = class Application extends Ember.Route.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "moment", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "userService", _descriptor3, this);
      (0, _defineProperty2.default)(this, "headTags", void 0);
      (0, _defineProperty2.default)(this, "target", '');
    }

    keepUserActive() {
      Ember.run.later(this, function () {
        this.amplify.currentUser.set('lastactive', parseInt(Date.now() / 1000).toString());
        this.userService.update(this.amplify.currentUser);
        this.keepUserActive();
      }, 1000 * 60 * 5);
    }

    async beforeModel(transition) {
      try {
        if (!this.isInitialized) {
          await this.amplify.initAmplify();
          await this.amplify.initClient();
          await this.amplify.initSession();
          this.amplify.currentUser.set('lastactive', parseInt(Date.now() / 1000).toString());
          this.userService.update(this.amplify.currentUser);
          this.keepUserActive();
        }

        this.set('isInitialized', true);
        this.set('amplify.target', transition.to.localName);
        let firstVisit = localStorage.getItem('firstvisit');
        console.log(transition);

        if (firstVisit !== '1' && transition.to.name == 'index' && this.amplify.currentClient.loginmode !== 'ANONYM') {}

        if (!this.get('amplify.isAuthenticated') && this.amplify.currentClient.loginmode !== 'ANONYM') {
          /* window.location.href =
            'https://' +
            this.amplify.currentClient.domain.split(',')[0].trim() +
            '/#/login';*/
          return;
        }
      } catch (e) {
        console.error(e);
      }
    }

    async model(data, transition) {
      return this.amplify.currentClient;
    }

    error(reason) {
      console.log(reason);
    }

    async setHeadTags(model, theme) {
      let headTags = await this.amplify.setHeadTags();
      this.set('headTags', headTags);
      return headTags;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "moment", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "userService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "error", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class));
  _exports.default = Application;
});