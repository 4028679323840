define("eventapp-stream/components/module-layouts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0yIFNoAX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"layoutId\"]],1],null]],null,{\"statements\":[[4,\"if\",[[24,[\"isIframe\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"module-layout\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row h-100\"],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"col-20 \",[28,\"if\",[[28,\"eq\",[[24,[\"layoutId\"]],2],null],\"h-70\",\"h-100\"],null]]]],[8],[0,\"\\n          \"],[5,\"module-wrapper\",[],[[\"@showNav\",\"@componentName\"],[true,\"\"]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"col-\",[24,[\"amplify\",\"currentTheme\",\"config\",\"moduleGrid\"]],\" module-layout\"]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"row h-100\"],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"col-20 \",[28,\"if\",[[28,\"eq\",[[24,[\"layoutId\"]],2],null],\"h-70\",\"h-100\"],null]]]],[8],[0,\"\\n          \"],[5,\"module-wrapper\",[],[[\"@showNav\",\"@componentName\"],[true,\"\"]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "eventapp-stream/components/module-layouts/template.hbs"
    }
  });

  _exports.default = _default;
});