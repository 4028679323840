define("eventapp-stream/impressum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fQ/75oP2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"impressum-wrapper\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"index\"]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"back-btn\"],[8],[0,\"\\n        Back\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n    \\n    \"],[1,[24,[\"amplify\",\"currentTheme\",\"config\",\"impress\"]],true],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "eventapp-stream/impressum/template.hbs"
    }
  });

  _exports.default = _default;
});