define("eventapp-stream/components/image-modal/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  let ImageModal = (_dec = Ember.inject.service('services/amplify'), _dec2 = Ember.computed('file'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ImageModal extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
    }

    get imagepath() {
      if (Ember.isEmpty(this.file)) {
        console.log('empty map');
        return;
      }

      return this.get('amplify').Storage.get(this.file.key, {
        level: 'public'
      });
    }

    didInsertImage() {
      if (this.onDidInsertElement) {
        this.onDidInsertElement();
      }
    }

    click() {
      this.onClose();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "imagepath", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "imagepath"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "didInsertImage", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertImage"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "click", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class));
  _exports.default = ImageModal;
});