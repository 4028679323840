define("eventapp-stream/helpers/striptags", ["exports", "event-components/helpers/striptags"], function (_exports, _striptags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _striptags.default;
    }
  });
  Object.defineProperty(_exports, "striptags", {
    enumerable: true,
    get: function () {
      return _striptags.striptags;
    }
  });
});