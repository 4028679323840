define("eventapp-stream/standalone/controller", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Standalone extends Ember.Controller.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layoutId", 1);
      (0, _defineProperty2.default)(this, "helper", true);
      (0, _defineProperty2.default)(this, "showIframe", false);
      console.log(this.userId);
      this.amplify.isStreamingApp = true;
      window.onkeydown = this.disableHelper.bind(this);
    }

    disableHelper() {
      var _event;

      if (((_event = event) === null || _event === void 0 ? void 0 : _event.keyCode) == 27) {
        this.set('helper', false);
      }
    }

  } // DO NOT DELETE: this is how TypeScript knows how to look up your controllers.


  _exports.default = Standalone;
});