define("eventapp-stream/components/module-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ydd3bJ6l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"module-wrapper\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showNav\"]]],null,{\"statements\":[[0,\"    \"],[5,\"module-nav\",[],[[\"@navHandler\"],[[22,\"setActiveModule\"]]]],[7,\"div\",true],[10,\"id\",\"nav-slot\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"component\",[[24,[\"amplify\",\"componentName\"]]],[[\"isStreamingApp\",\"preserveState\",\"currentVote\",\"currentChatroom\",\"currentWallId\",\"isMobile\",\"class\"],[true,true,[24,[\"currentVote\"]],[24,[\"currentChatroom\"]],[24,[\"currentWall\"]],[28,\"hash\",null,[[\"any\"],[false]]],\"ember-module\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "eventapp-stream/components/module-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});