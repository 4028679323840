define("eventapp-stream/components/external-conference/webex/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kk0B58iN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"webexteams-widget\"],[10,\"style\",\"width: 100%; height: 85vh;\"],[8],[9],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "eventapp-stream/components/external-conference/webex/template.hbs"
    }
  });

  _exports.default = _default;
});