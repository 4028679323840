define("eventapp-stream/components/stream/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  let Stream = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service('services/amplify'), _dec3 = Ember._action, _dec(_class = (_class2 = class Stream extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    // normal class body definition here
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
    }

    setActiveModule(module) {
      if (module.header != 'Fullscreen') {
        this.amplify.set('currentModule', module.header);
        this.amplify.set('componentName', module.key);
      }

      this.amplify.set('unityIframeOpen', false);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "setActiveModule", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "setActiveModule"), _class2.prototype)), _class2)) || _class);
  _exports.default = Stream;
});