define("eventapp-stream/components/external-conference/webex/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  let WebexConference = (_dec = (0, _component.tagName)(''), _dec2 = Ember.inject.service('services/amplify'), _dec(_class = (_class2 = class WebexConference extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
    }

    didInsertElement() {
      this.initWebex();
    }

    async initWebex() {
      console.log('test1');
      await this.injectScript('https://cdn.jsdelivr.net/npm/webex/umd/webex.min.js');
      console.log('test2');
      await this.injectScript('https://code.s4d.io/widget-space/production/bundle.js');
      console.log('test3');
      await this.injectStylesheet('https://code.s4d.io/widget-space/production/main.css');
      console.log('test4');
      const tokens = await this.getTokens();
      await this.initWebexClient(tokens);
      let room = await this.getRoom('RoomName');
      const widgetEl = document.getElementById('webexteams-widget');
      let params = {
        guestToken: tokens.token2,
        destinationId: 'Y2lzY29zcGFyazovL3VybjpURUFNOmV1LWNlbnRyYWwtMV9rL1JPT00vNWUxMGRmZTAtODMzZC0xMWViLThhYmItODNjMTY5YTJiMzJm',
        destinationType: 'spaceId',
        initialActivity: 'meet',
        spaceActivities: {
          files: true,
          message: true,
          people: true
        }
      };
      console.log('init Room', params); // Init a new widget

      webex.widget(widgetEl).spaceWidget(params);
    }

    async getRoom(title) {
      let rooms = await this._webex.rooms.list();
      console.log(rooms);
      let room = rooms.items.findBy('nametitle', title);

      if (room) {
        console.log('find room', room);
        return title;
      } else {
        let data = await this._webex.rooms.create({
          title
        });
        console.log('find room', data);
        return data;
      }
    }

    async getTokens() {
      let resp = await fetch('https://oa7mgzpmm0.execute-api.eu-central-1.amazonaws.com/dev/', {
        method: 'POST',
        body: JSON.stringify({
          userid: '1',
          username: 'gerdner'
        })
      });
      return resp.json();
    }

    async initWebexClient(tokens) {
      return new Promise(resolve => {
        const webex2 = Webex.init({
          credentials: {
            access_token: tokens.token
          }
        });
        this._webex = webex2;
        webex2.once(`ready`, resolve);
      });
    }

    injectScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.getElementsByTagName('head')[0].appendChild(script);
      });
    }

    injectStylesheet(src) {
      return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = src;
        link.onload = resolve;
        link.onerror = reject;
        document.getElementsByTagName('head')[0].appendChild(link);
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = WebexConference;
});