define("eventapp-stream/initializers/amplify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('controller', 'amplify', 'service:services/amplify');
    application.inject('component', 'amplify', 'service:services/amplify');
    application.inject('route', 'amplify', 'service:services/amplify');
    application.inject('controller', 'intl', 'service:intl');
    application.inject('component', 'intl', 'service:intl');
    application.inject('route', 'intl', 'service:intl');
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});