define("eventapp-stream/components/module-wrapper/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  let ModuleWrapper = (_dec = Ember.inject.service('services/amplify'), _dec2 = Ember._action, (_class = class ModuleWrapper extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    setActiveModule(navItem) {
      if (navItem.key === this.amplify.get("componentName") && navItem.header === this.amplify.get("currentModule")) {
        // Fix for same module refresh route
        this.set('amplify.componentName', "");
        this.set('amplify.currentModule', "");
        setTimeout(() => {
          this.set('amplify.componentName', navItem.key);
          this.set('amplify.currentModule', navItem.header);
        }, 0);
      } else {
        this.set('amplify.componentName', navItem.key);
        this.set('amplify.currentModule', navItem.header);
      }
    }

    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
      (0, _defineProperty2.default)(this, "showNav", true);
      this.amplify.on('unity:vote', this, 'openVote');
      this.amplify.on('unity:chat', this, 'openChat');
      this.amplify.on('unity:wall', this, 'openWall');
    }

    openWall(args) {
      this.set('currentWall', args);
      this.set('amplify.componentName', 'twitter-wall');
      console.log('args', ...arguments);
    }

    openVote(args) {
      this.set('currentVote', args);
      this.set('amplify.componentName', 'vote-list-module');
      console.log('args', ...arguments);
    }

    openChat(args) {
      this.set('currentChatroom', {
        id: args
      });
      this.set('amplify.componentName', 'app-chat');
      console.log('args', ...arguments);
    }

    willDestroyElement() {
      this.amplify.off('unity:vote', this, 'openVote');
      this.amplify.off('unity:chat', this, 'openChat');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setActiveModule", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setActiveModule"), _class.prototype)), _class));
  _exports.default = ModuleWrapper;
});