define("eventapp-stream/helpers/firstletter", ["exports", "event-components/helpers/firstletter"], function (_exports, _firstletter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _firstletter.default;
    }
  });
  Object.defineProperty(_exports, "firstletter", {
    enumerable: true,
    get: function () {
      return _firstletter.firstletter;
    }
  });
});