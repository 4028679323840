define("eventapp-stream/router", ["exports", "@babel/runtime/helpers/esm/defineProperty", "eventapp-stream/config/environment"], function (_exports, _defineProperty2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "location", _environment.default.locationType);
      (0, _defineProperty2.default)(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('anonym');
    this.route('account');
    this.route('login');
    this.route('init', {
      path: '/i/:user_id/:user_name'
    });
    this.route('nouser');
    this.route('impressum');
    this.route('datenschutz');
    this.route('netiquette');
    this.route('standalone');
  });
});