define("eventapp-stream/application-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oy2X7KQO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-loading\"],[8],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"lds-roller\"],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "eventapp-stream/application-loading/template.hbs"
    }
  });

  _exports.default = _default;
});