define("eventapp-stream/nouser/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Nouser extends Ember.Route.extend({// anything which *must* be merged to prototype here
  }) {
    beforeModel() {
      console.log(this.get('amplify.currentClient.loginmode'));

      if (this.get('amplify.currentClient.loginmode') === 'CODE') {
        this.transitionTo('login');
      }
    }

  }

  _exports.default = Nouser;
});