define("eventapp-stream/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EzulihIC",
    "block": "{\"symbols\":[],\"statements\":[[5,\"app-css\",[],[[\"@client\",\"@theme\"],[[22,\"model\"],[23,0,[\"amplify\",\"currentTheme\",\"config\"]]]]],[0,\"\\n\\n\"],[5,\"app-navbar\",[],[[],[]]],[0,\"\\n\"],[7,\"div\",true],[10,\"id\",\"image-modal\"],[8],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"id\",\"footer-destination\"],[8],[9],[0,\"\\n\"],[7,\"style\",true],[8],[0,\"\\n  \"],[1,[24,[\"amplify\",\"currentTheme\",\"config\",\"customcss\"]],true],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"notification-container\",null,[[\"position\"],[\"bottom\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "eventapp-stream/application/template.hbs"
    }
  });

  _exports.default = _default;
});