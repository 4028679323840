define("eventapp-stream/event-components/tests/app.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | app');
  QUnit.test('app/components/active-users/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/active-users/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-agenda-appointment-owner/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-agenda-appointment-owner/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-agenda-appointment/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-agenda-appointment/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-agenda-item/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-agenda-item/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-agenda/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-agenda/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-appointments/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-appointments/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-avatar-module/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-avatar-module/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-avatar-photo-detail/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-avatar-photo-detail/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-avatar-photo/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-avatar-photo/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-avatar-upload/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-avatar-upload/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-chat-pm-messages/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-chat-pm-messages/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-chat-pm/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-chat-pm/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-chat-private/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-chat-private/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-chat-profiles/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-chat-profiles/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-chat/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-chat/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-css/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-css/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-dashboard-item/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-dashboard-item/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-dashboard/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-dashboard/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-desktop-dashboard-item/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-desktop-dashboard-item/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-frage/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-frage/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-fragebogen/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-fragebogen/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-gallery-post/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-gallery-post/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-gallery/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-gallery/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-map-beacondetail/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-map-beacondetail/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-map/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-map/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-meeting/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-meeting/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-navbar/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-navbar/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-programm-module/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-programm-module/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-register/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-register/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-tab/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-tab/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-tabs/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-tabs/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-umfrage-vote/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-umfrage-vote/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/app-umfrage/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/app-umfrage/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/aws-image/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/aws-image/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/hls-stream/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/hls-stream/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/iframe-wrapper/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/iframe-wrapper/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/infinity-loader/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/infinity-loader/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/live-datetime/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/live-datetime/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/module-nav/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/module-nav/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/pwa-message/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/pwa-message/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/splashscreen/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/splashscreen/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/svg-mask/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/svg-mask/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/twitter-filter/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/twitter-filter/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/twitter-module/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/twitter-module/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/twitter-post-create/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/twitter-post-create/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/twitter-post/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/twitter-post/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/twitter-wall/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/twitter-wall/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/unity-nav/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/unity-nav/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/user-card/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/user-card/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/vote-chart/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/vote-chart/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/vote-list-module/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/vote-list-module/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/vote-module/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/vote-module/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/x-button/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/x-button/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/eventbus/service.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/eventbus/service.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/awsimage.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/awsimage.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/css-name.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/css-name.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/faved.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/faved.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/findby.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/findby.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/firstletter.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/firstletter.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/input-value.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/input-value.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/link-blank.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/link-blank.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/map-by-question.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/map-by-question.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/nl2br.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/nl2br.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/replace.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/replace.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/special-counter.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/special-counter.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/split.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/split.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/striptags.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/striptags.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/to-string.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/to-string.js should pass ESLint\n\n');
  });
  QUnit.test('app/helpers/truncate.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/helpers/truncate.js should pass ESLint\n\n');
  });
  QUnit.test('app/services/amplify/service.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/services/amplify/service.js should pass ESLint\n\n');
  });
  QUnit.test('app/services/auth/service.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/services/auth/service.js should pass ESLint\n\n');
  });
});