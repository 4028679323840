define("eventapp-stream/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LyVS+xUZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"class\",\"text-color-6\"],[8],[0,\"\\n  \"],[1,[24,[\"amplify\",\"currentTheme\",\"data\",\"textKeys\",\"logintext\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "eventapp-stream/login/template.hbs"
    }
  });

  _exports.default = _default;
});