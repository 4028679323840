define("eventapp-stream/components/video-iframe/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DH3MwoKg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"amplify\",\"currentTheme\",\"config\",\"meetingUrl\"]]],null,{\"statements\":[[0,\"  \"],[7,\"iframe\",true],[11,\"class\",[29,[\"videochat\\n      \",[28,\"if\",[[24,[\"amplify\",\"unityIframeOpen\"]],\" unity-open\"],null],\"\\n      \",[28,\"if\",[[24,[\"amplify\",\"videoChatOpen\"]],\" open\"],null]]]],[10,\"id\",\"videochat\"],[10,\"allow\",\"camera *;microphone *;autoplay 'src';\"],[11,\"src\",[29,[[24,[\"amplify\",\"currentTheme\",\"config\",\"meetingUrl\"]],\"?userid=\",[24,[\"amplify\",\"currentUser\",\"id\"]],\"&iframe=1\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "eventapp-stream/components/video-iframe/template.hbs"
    }
  });

  _exports.default = _default;
});