define("eventapp-stream/index/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SystemMessageTypes = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SystemMessageTypes;
  _exports.SystemMessageTypes = SystemMessageTypes;

  (function (SystemMessageTypes) {
    SystemMessageTypes["VideoChat"] = "VideoChat";
  })(SystemMessageTypes || (_exports.SystemMessageTypes = SystemMessageTypes = {}));

  let Application = (_dec = Ember.inject.service('services/amplify'), _dec2 = Ember.inject.service('user'), _dec3 = Ember.inject.service('chatline'), _dec4 = Ember.computed.alias('amplify.currentTheme.config.streamConfig'), _dec5 = (0, _object.observes)('amplify.currentTheme.config.streamConfig'), _dec6 = (0, _object.observes)('event3d'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class Application extends Ember.Controller.extend({// anything which *must* be merged to prototype here
  }) {
    onStreams() {
      this.set('activeStream', this.get('amplify.currentTheme.config.streamConfig.firstObject'));
    }

    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "userService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "chatService", _descriptor3, this);
      (0, _defineProperty2.default)(this, "activeStream", void 0);
      (0, _defineProperty2.default)(this, "queryParams", ['event3d']);
      (0, _initializerDefineProperty2.default)(this, "model", _descriptor4, this);
      (0, _defineProperty2.default)(this, "event3d", null);
      (0, _defineProperty2.default)(this, "layoutId", 1);
      (0, _defineProperty2.default)(this, "helper", true);
      (0, _defineProperty2.default)(this, "showIframe", false);
      this.amplify.isStreamingApp = true;
      window.onkeydown = this.disableHelper.bind(this);
      window.addEventListener('message', async message => {
        const iframe = document.getElementById('videochat');
        const iframeUnity = document.getElementById('unity-iframe');

        if (iframeUnity != null) {
          console.log('UNITY PROPAGATE');

          if (message.origin.includes('meeting')) {
            var _iframeUnity$contentW;

            (_iframeUnity$contentW = iframeUnity.contentWindow) === null || _iframeUnity$contentW === void 0 ? void 0 : _iframeUnity$contentW.postMessage(message.data, '*');
          }
        }

        if (iframe != null) {
          if (message.data == 'invite') {
            this.amplify.set('videoChatOpen', true);
          } else if (message.data == 'acceptedInvite') {
            this.amplify.set('videoChatOpen', true);
          } else if (message.data == 'declinedInvite') {
            this.amplify.set('videoChatOpen', false);
          } else if (message.data == 'leave') {
            this.amplify.set('videoChatOpen', false);
          } else if (message.data.data == 'smallIframe') {
            this.amplify.set('unityIframeOpen', false);
          } else if (message.data.data == 'largeIframe') {
            this.amplify.set('unityIframeOpen', true);
          } else if (message.data.type == 'privateChat') {
            this.amplify.trigger('nav:goto', {
              key: 'app-chat-private',
              header: 'Chat'
            });
            Ember.run.later(async () => {
              let user = await this.userService.models.findBy('id', message.data.data);
              console.log("trigger p chat");

              if (user) {
                this.amplify.trigger('nav:gotoprivate', user);
              }
            }, 1000);
          } else if (message.data.type == 'unityInviteVideochat') {
            var _iframe$contentWindow;

            this.amplify.set('videoChatOpen', true);
            (_iframe$contentWindow = iframe.contentWindow) === null || _iframe$contentWindow === void 0 ? void 0 : _iframe$contentWindow.postMessage({
              type: 'invite',
              data: message.data.data
            }, '*');
          } else if (message.data.type == 'unitySendPrivateMessage') {
            this.amplify.newPrivateMessage(message.data.data.message, message.data.data.username);
          } else if (message.data.type == 'VideochatEnded' || message.data.type == 'MissedCall') {
            this.amplify.newSystemMessage(SystemMessageTypes.VideoChat, { ...message.data.data,
              messageType: message.data.type
            });
          }
        }
      });
    }

    onEvent3d() {
      if (this.event3d === 'open') {
        Ember.run.later(this, function () {
          this.switchVideo();
        }, 1000);
      }
    }

    changeLayout(layoutId) {
      this.set('layoutId', layoutId);
    }

    videoFullscreen() {
      var _document$querySelect, _document$querySelect2;

      (_document$querySelect = document.querySelector('video')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.requestFullscreen();

      if (((_document$querySelect2 = document.querySelector('iframe')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.id) === 'unity-iframe') {
        console.log('Unityiframeopen', !this.amplify.unityIframeOpen);
        this.amplify.set('unityIframeOpen', !this.amplify.unityIframeOpen);
      } else {
        var _document$querySelect3;

        (_document$querySelect3 = document.querySelector('iframe')) === null || _document$querySelect3 === void 0 ? void 0 : _document$querySelect3.requestFullscreen();
      }
    }

    switchVideo() {
      this.set('showIframe', true);
      this.set('activeStream', null);
      setTimeout(() => {
        this.amplify.set('unityIframeOpen', true);
      }, 100);
    }

    changeStream(stream) {
      console.log(stream);

      if (stream.type == 'Link') {
        const tab = window.open(stream.url, '_blank');

        if (tab != undefined) {
          tab.focus();
        }
      } else {
        Ember.run.next(this, function () {
          this.set('showIframe', true);
        });
        Ember.run.next(this, function () {
          this.set('activeStream', stream);
          this.set('showIframe', false);
        });
        this.set('false', true);
      }
    }

    disableHelper() {
      var _event;

      if (((_event = event) === null || _event === void 0 ? void 0 : _event.keyCode) == 27) {
        this.set('helper', false);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "userService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "chatService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onStreams", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onStreams"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onEvent3d", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onEvent3d"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "changeLayout", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "changeLayout"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "videoFullscreen", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "videoFullscreen"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "switchVideo", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "switchVideo"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "changeStream", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "changeStream"), _class.prototype)), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your controllers.

  _exports.default = Application;
});