define("eventapp-stream/components/video-iframe/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let VideoIframe = (_dec = Ember.inject.service('services/amplify'), (_class = class VideoIframe extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
      (0, _defineProperty2.default)(this, "listening", false);
      this.amplify.on('videochat:invite', this, 'onInviteUser');
    }

    onInviteUser(userId) {
      const iframe = document.getElementById('videochat');

      if (iframe != null) {
        this.amplify.set('videoChatOpen', true);
        iframe.contentWindow.postMessage({
          type: 'invite',
          data: userId
        }, '*');
      }
    }

    willDestroyElement() {
      this.amplify.off('videochat:invite', this, 'onInviteUser');
    }

    didInsertElement() {}

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = VideoIframe;
});