define("eventapp-stream/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Application extends Ember.Route.extend({// anything which *must* be merged to prototype here
  }) {
    afterModel() {
      if (this.get('amplify.currentUser') == null) {
        if (this.get('amplify.currentTheme.config.streamingAppAllowAnonymUsers') == true) {
          return;
        }
      }
    }

    async model() {
      return this.get('amplify.currentTheme.config.streamConfig');
    }

    setupController(controller, model) {
      if (!model) {
        return;
      }

      model = model.map((s, i) => {
        return { ...s,
          id: i + 1
        };
      });
      controller.set('activeStream', model.firstObject);
      controller.set('model', model);

      this._super(...arguments);
    }

  }

  _exports.default = Application;
});