define("eventapp-stream/app", ["exports", "@babel/runtime/helpers/esm/defineProperty", "eventapp-stream/resolver", "ember-load-initializers", "eventapp-stream/config/environment", "@aws-amplify/core"], function (_exports, _defineProperty2, _resolver, _emberLoadInitializers, _environment, _core) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let root_domain;

  if (location.host.includes('localhost')) {
    let temp = location.host.split('.').reverse();
    root_domain = 'localhost';
  } else if (location.href.includes('192.168.8.161')) {
    root_domain = '192.168.8.161';
  } else {
    let temp = location.host.split('.').reverse();
    root_domain = '.' + temp[1] + '.' + temp[0];
  }

  _environment.default.awsmobile.Auth.cookieStorage.domain = `${root_domain}`;

  _core.default.configure(_environment.default.awsmobile);

  class App extends Ember.Application {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "modulePrefix", _environment.default.modulePrefix);
      (0, _defineProperty2.default)(this, "podModulePrefix", _environment.default.podModulePrefix);
      (0, _defineProperty2.default)(this, "Resolver", _resolver.default);
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});