define("eventapp-stream/nouser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/XAtV+Bx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"header-destination\"]],{\"statements\":[[4,\"link-to\",null,[[\"route\"],[\"index\"]],{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"class\",\"back-icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\"],[\"fade-in position-relative\",\"index\"]],{\"statements\":[[0,\"    Login\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"text-color-6\"],[8],[0,\"\\n  Sie müssen sich einloggen um an diesem Event teilzunehmen. Klicken Sie dazu auf den Link aus der Einladungs E-Mail.\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"text-color-6\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"forgot-link\"],[8],[0,\"\\n    Einladungs-Code erneut zusenden\\n\"],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"alert alert-danger\"],[10,\"role\",\"alert\"],[10,\"style\",\"margin-top: 10px;\"],[8],[0,\"\\n        \"],[1,[22,\"error\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[24,[\"firstname\"]],\"Vorname\"]]],false],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[24,[\"lastname\"]],\"Nachname\"]]],false],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[24,[\"email\"]],\"E-Mail\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[5,\"x-button\",[[12,\"class\",\"btn btn-primary btn-fullwidth btn-lg\"]],[[\"@task\",\"@arg\"],[[22,\"resendInviteEmail\"],[22,\"email\"]]],{\"statements\":[[0,\"\\n      ABSENDEN\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "eventapp-stream/nouser/template.hbs"
    }
  });

  _exports.default = _default;
});