define("eventapp-stream/datenschutz/controller", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let Datenschutz = (_dec = Ember.inject.service('services/amplify'), (_class = class Datenschutz extends Ember.Controller.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your controllers.

  _exports.default = Datenschutz;
});